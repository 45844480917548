import { TagColor } from '../../../../components/TSC/Tag/TagColors';

/* These value will be used to sort the column */
export const SUBSCRIBER_FIELD_BRAND = 'BRAND';
export const SUBSCRIBER_FIELD_FULL_NAME = 'FULL_NAME';
export const SUBSCRIBER_FIELD_EMAIL = 'EMAIL';
export const SUBSCRIBER_FIELD_ENGAGEMENT = 'RATING';
export const SUBSCRIBER_FIELD_TAG = 'tag';

export const SUBSCRIBER_TAG_CATEGORY = 'Subscriber';

export const getStarCount = (starEnum) => {
  switch (starEnum) {
    case 'ONE_STAR':
      return 1;
    case 'TWO_STARS':
      return 2;
    case 'THREE_STARS':
      return 3;
    case 'FOUR_STARS':
      return 4;
    case 'FIVE_STARS':
      return 5;
    default:
      return 0;
  }
};

export const customerStatuses = {
  SUBSCRIBED: 'Subscribed',
  UNSUBSCRIBED: 'Unsubscribed',
  BOUNCED: 'Bounced',
  COMPLAINED: 'Complained'
};

export const getCustomerStatusColor = (enumValue) => {
  switch (enumValue) {
    case 'SUBSCRIBED':
      return TagColor.GREEN;
    case 'UNSUBSCRIBED':
      return TagColor.ORANGE;
    case 'BOUNCED':
      return TagColor.BROWN;
    case 'COMPLAINED':
      return TagColor.BROWN;
    default:
      return null;
  }
};

export const customerLevels = {
  JUNIOR_MEMBER: 'Junior Member',
  REWARDS_MEMBER: 'Rewards Member',
  SILVER_ELITE: 'Silver Elite',
  GOLD_ELITE: 'Gold Elite',
  PLATINUM_ELITE: 'Platinum Elite'
};

export const renderUserFullName = (entity, suffix = '') => {
  if (!entity) {
    return '<Not set>';
  }
  let fullName = entity && entity.firstName ? `${entity.firstName} ` : '';
  fullName += entity && entity.lastName ? entity.lastName : '';
  if (fullName.trim() === '') {
    return '<Not set>';
  }
  return fullName + suffix;
};
export const renderUserAvatarText = (entity) => {
  if (!entity) {
    return '';
  }
  if (entity.firstName && entity.firstName.trim() !== '' && (!entity.lastName || entity.lastName.trim() === '')) {
    return entity.firstName.substr(0, 2).toUpperCase();
  }
  if (entity.lastName && entity.lastName.trim() !== '' && (!entity.firstName || entity.firstName.trim() === '')) {
    return entity.lastName.substr(0, 2).toUpperCase();
  }

  if (entity.firstName && entity.firstName.trim() !== '' && entity.lastName && entity.lastName.trim() !== '') {
    return (entity.firstName.substr(0, 1) + entity.lastName.substr(0, 1)).toUpperCase();
  }
  return entity.email.substr(0, 2);
};
