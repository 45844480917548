import {TagColor} from '../../../../components/TSC/Tag/TagColors';
import {defaultCampaignEntity, defaultMailOption} from './schema';

/* These value will be used to sort the column */
export const CAMPAIGN_FIELD_BRAND = 'BRAND';
export const CAMPAIGN_FIELD_MAIL_SUBJECT = 'MAIL_SUBJECT';
export const CAMPAIGN_FIELD_NAME = 'CAMPAIGN_NAME';
export const CAMPAIGN_FIELD_CREATED_DATE = 'CREATED_DATE';
export const CAMPAIGN_FIELD_ENABLED = 'ENABLED';
export const CAMPAIGN_FIELD_STATUS = 'STATUS';
export const CAMPAIGN_FIELD_PROGRESS = 'PROGRESS';

export const CAMPAIGN_TAG_CATEGORY = 'Campaign';

export const EXECUTION_SUBSCRIBER = 'SUBSCRIBER';
export const EXECUTION_STATUS = 'STATUS';
export const EXECUTION_SENT_DATE = 'SENT_DATE';

export const CAMPAIGN_STRATEGY_NONE_VALUE = 'NONE';

export const CAMPAIGN_TRIGGER_RUN = 'RUN';
export const CAMPAIGN_TRIGGER_RE_RUN = 'RE_RUN';
export const CAMPAIGN_TRIGGER_RESUME = 'RESUME';
export const CAMPAIGN_TRIGGER_REVERT_TO_DRAFT = 'REVERT_TO_DRAFT';
export const CAMPAIGN_TRIGGER_STOP = 'STOP';
export const CAMPAIGN_TRIGGER_TEST = 'TEST';

export const CAMPAIGN_TRIGGER_ACTION_SAVE = 'SAVE';
export const CAMPAIGN_TRIGGER_ACTION_SEND_NOW = 'SEND_NOW';
export const CAMPAIGN_TRIGGER_ACTION_RE_RUN = 'RE_RUN';
export const CAMPAIGN_TRIGGER_ACTION_RESUME = 'RESUME';
export const CAMPAIGN_TRIGGER_ACTION_SCHEDULE = 'SCHEDULE';
export const CAMPAIGN_TRIGGER_ACTION_STOP = 'STOP';
export const CAMPAIGN_TRIGGER_ACTION_SEND_TEST = 'SEND_TEST';

export const CAMPAIGN_ENABLED_TRUE = 'TRUE';
export const CAMPAIGN_ENABLED_FALSE = 'FALSE';
export const campaignEnableStatus = {
  TRUE: 'Active',
  FALSE: 'Inactive'
};
export const getEnabledStatusColor = (enumValue) => {
  switch (enumValue) {
    case 'TRUE':
      return TagColor.CYAN;
    case 'FALSE':
      return TagColor.RED;
    default:
      return null;
  }
};

export const CAMPAIGN_TYPE_AUTOMATE = 'AUTOMATED';
export const CAMPAIGN_TYPE_REGULAR = 'REGULAR';
export const CAMPAIGN_TYPE_MAIL = 'CAMPAIGN_MAIL';
export const campaignTypes = {
  REGULAR: 'Manual',
  AUTOMATED: 'Automate',
  CAMPAIGN_MAIL: 'Campaign Mail',
};
export const getCampaignTypesColor = (enumValue) => {
  switch (enumValue) {
    case 'REGULAR':
      return TagColor.LIGHT_BLUE;
    case 'AUTOMATED':
      return TagColor.TEAL;
    case 'CAMPAIGN_MAIL':
      return TagColor.INDIGO;
    default:
      return null;
  }
};

export const CAMPAIGN_STATUS_NOT_START = 'NOT_START';
export const CAMPAIGN_STATUS_SCHEDULED = 'SCHEDULED';
export const CAMPAIGN_STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const CAMPAIGN_STATUS_FINISHED = 'FINISHED';
export const CAMPAIGN_STATUS_STOPPED = 'STOPPED';
export const campaignStatuses = {
  NOT_START: 'Draft',
  SCHEDULED: 'Scheduled',
  IN_PROGRESS: 'In Progress',
  FINISHED: 'Finished',
  STOPPED: 'Stopped',
};
export const getCampaignStatusesColor = (enumValue) => {
  switch (enumValue) {
    case CAMPAIGN_STATUS_NOT_START:
      return TagColor.GREY;
    case CAMPAIGN_STATUS_SCHEDULED:
      return TagColor.DEEP_ORANGE;
    case CAMPAIGN_STATUS_IN_PROGRESS:
      return TagColor.ORANGE;
    case CAMPAIGN_STATUS_FINISHED:
      return TagColor.GREEN;
    case CAMPAIGN_STATUS_STOPPED:
      return TagColor.BROWN;
    default:
      return null;
  }
};

export const CAMPAIGN_STRATEGY_NONE = 'NONE';
export const CAMPAIGN_STRATEGY_PROMOTION = 'PROMOTION';
export const CAMPAIGN_STRATEGY_BIRTH_DAY = 'BIRTH_DAY';
export const CAMPAIGN_STRATEGY_WINBACK = 'WINBACK';
export const CAMPAIGN_STRATEGY_CART_REMINDER = 'CART_REMINDER';
export const campaignStrategyTypes = {
  NONE: 'Default campaign',
  PROMOTION: 'Promotion',
  BIRTH_DAY: 'Happy birthday',
  // WINBACK: 'Winback',
  // CART_REMINDER: 'Cart reminder',
};

export const campaignExecutionStatuses = {
  NOT_START: 'Not Start',
  IN_PROGRESS: 'In Progress',
  SENT: 'Sent',
  BOUNCED: 'Bounced',
  SOFT_BOUNCED: 'Soft Bounced',
  COMPLAINED: 'Complained',
  DELIVERED: 'Delivered',
  FAILED: 'Failed'
};
export const getCampaignExecutionStatusesColor = (enumValue) => {
  switch (enumValue) {
    case 'NOT_START':
      return TagColor.GREY;
    case 'IN_PROGRESS':
      return TagColor.ORANGE;
    case 'SENT':
      return TagColor.GREEN;
    case 'BOUNCED':
      return TagColor.BROWN;
    case 'SOFT_BOUNCED':
      return TagColor.BROWN;
    case 'COMPLAINED':
      return TagColor.BROWN;
    case 'DELIVERED':
      return TagColor.BLUE;
    case 'FAILED':
      return TagColor.RED;
    default:
      return null;
  }
};

export const CAMPAIGN_MONITOR_SUBSCRIBER = 'MONITOR_VIEW_CLICK_SUBSCRIBER';

export const CAMPAIGN_PARAM_NAMES = {
  'campaignParams.discountCode': 'Fixed Discount Code',
  'campaignParams.numberOfHours': 'Number of Hours',
  'campaignParams.numberOfOrders': 'Number of Orders',
  'campaignParams.productLink': 'Product Link',
  'campaignParams.productName': 'Product Name',
  'campaignParams.promotionProgram': 'Promotion Program',
  'campaignParams.shippingNote': 'Shipping Note',
  'campaignParams.shippingDate': 'Shipping Date',
  'campaignParams.saleEndDate': 'The end date of the sale',
  'campaignParams.text1': 'Custom Text 1',
  'campaignParams.text2': 'Custom Text 2',
  'campaignParams.text3': 'Custom Text 3',
  'campaignParams.text4': 'Custom Text 4',
  'campaignParams.text5': 'Custom Text 5'
};

export const filterForPredefinedParams = (campaign = defaultCampaignEntity()) => {
  if (!campaign.params) {
    return [];
  }
  return campaign.params.filter((param) => CAMPAIGN_PARAM_NAMES[param.name] === undefined) || [];
};
export const filterForCampaignParams = (campaign = defaultCampaignEntity()) => {
  if (!campaign.params) {
    return [];
  }
  return campaign.params.filter((param) => CAMPAIGN_PARAM_NAMES[param.name] !== undefined) || [];
};

export const CAMPAIGN_EMAIL_TYPE = {
  MARKETING_EMAIL: {
    email: 'info',
    name: ''
  },
  SYSTEM_INFORMATION: {
    email: 'noreply',
    name: ''
  },
  SYSTEM_INVOICE: {
    email: 'payment',
    name: 'Payment'
  },
};

export const mailOptionsByDomain = (brand, isSystemCampaign) => {
  try {
    let email = brand.contactEmail
    let domain = email.substring(email.indexOf('@'))
    let brandName = brand.name
    if (isSystemCampaign) {
      return [
        defaultMailOption(), {
          email: CAMPAIGN_EMAIL_TYPE.SYSTEM_INFORMATION.email + domain,
          name: (CAMPAIGN_EMAIL_TYPE.SYSTEM_INFORMATION.name + ' ' + brandName).trim()
        }, {
          email: CAMPAIGN_EMAIL_TYPE.SYSTEM_INVOICE.email + domain,
          name: (CAMPAIGN_EMAIL_TYPE.SYSTEM_INVOICE.name + ' ' + brandName).trim()
        }].concat(buildMailOptionForScraft(domain, brandName))
    } else {
      return [
        defaultMailOption(), {
          email: CAMPAIGN_EMAIL_TYPE.MARKETING_EMAIL.email + domain,
          name: (CAMPAIGN_EMAIL_TYPE.MARKETING_EMAIL.name + ' ' + brandName).trim()
        }].concat(buildMailOptionForScraft(domain, brandName))
    }
  } catch (e) {
    return [defaultMailOption()]
  }
}

let buildMailOptionForScraft = function (domain, brandName) {
  if (brandName !== 'S-Craft') {
    return []
  }
  return [{
    email: 'support' + domain,
    name: brandName
  }]
}
