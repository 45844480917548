import axios from 'axios';
import UserSession from './UserSession';

export const API_HOST = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:5555';
export const AMS_API_HOST = process.env.REACT_APP_API_AMS_URL ? process.env.REACT_APP_API_AMS_URL : 'http://localhost:8010/notification-svc';
// export const API_HOST = 'http://localhost:5555';
const defaultConfig = {
  baseURL: API_HOST,
  timeout: 120000,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Headers': '*'
  }
};

const instance = axios.create(defaultConfig);

instance.interceptors.request.use(
  (config) => {
    if (!UserSession.getToken()) {
      return config;
    }
    return ({
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${UserSession.getToken()}`
      }
    });
  },
  (error) => Promise.reject(error)
);

// Configure this so that axios will catch 400 error in response
// Reject only if the status code is greater than or equal to 500;
// instance.defaults.validateStatus = (status) => status >= 500;

instance.interceptors.response.use(
  (response) => response,
  // eslint-disable-next-line consistent-return
  (error) => {
    if (!error.response) {
      alert('NETWORK ERROR');
      return;
    }
    const code = error.response.status;
    const response = error.response.data;
    const originalRequest = error.config;

    // eslint-disable-next-line no-underscore-dangle
    if (code === 401 && !originalRequest._retry) {
      // eslint-disable-next-line no-underscore-dangle
      UserSession.logout();
      originalRequest._retry = true;
      window.location.href = '/auth/login';
    }

    // eslint-disable-next-line consistent-return
    return Promise.reject(error);
  }
);

export default instance;
