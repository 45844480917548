import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import moment from 'moment';
import { TagColor } from '../../../../../components/TSC/Tag/TagColors';
import { requestCampaignsStatistic } from '../api';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  actions: {
    justifyContent: 'flex-end'
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1)
  },
  unassigned: {
    color: TagColor.RED
  }
}));

function CampaignsStatistic({ className, ...rest }) {
  const classes = useStyles();
  const [data, setData] = useState([]);

  useEffect(() => {
    let mounted = true;

    // eslint-disable-next-line max-len
    requestCampaignsStatistic('?enabled=true&archived=false&type=CAMPAIGN_MAIL&sortBy=UPDATED_DATE&direction=DESC&size=10', (response) => {
      if (mounted) {
        setData(response.data);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        // action={<GenericMoreButton />}
        title="Campaign Statistic"
      />
      <Divider/>
      <CardContent className={classes.content}>
        <PerfectScrollbar options={{ suppressScrollY: true }}>
          <div className={classes.inner}>
            {data && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Campaign</TableCell>
                    <TableCell>Sent</TableCell>
                    <TableCell>Opens</TableCell>
                    <TableCell>Clicks</TableCell>
                    <TableCell>Bounces</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((entity) => (
                    <TableRow
                      hover
                      key={entity.id}
                    >
                      <TableCell className={classes.name}>
                        <Typography variant="h6">
                          {entity.name}
                        </Typography>
                        <Typography variant="body1">
                          {entity.brand.name}
                        </Typography>
                        <Typography variant="body2">
                          {moment(entity.lastModifiedDate).format(
                            'DD MMM YYYY | HH:mm'
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" className={classes.sent}>
                          {entity.sent}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" className={classes.opened}>
                          {entity.opened}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" className={classes.clicked}>
                          {entity.clicked}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" className={classes.bounced}>
                          {entity.bounced}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          color="primary"
                          component={RouterLink}
                          size="small"
                          to={entity.type === 'CAMPAIGN_MAIL'
                            ? `/aems-marketing/campaigns/edit/${entity.id}`
                            : `/aems-marketing/system-campaigns/edit/${entity.id}`}
                          variant="outlined"
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          component={RouterLink}
          size="small"
          to="/aems-marketing/tickets"
          variant="text"
        >
          See all
          <ArrowForwardIcon className={classes.arrowForwardIcon}/>
        </Button>
      </CardActions>
    </Card>
  );
}

CampaignsStatistic.propTypes = {
  className: PropTypes.string
};

export default CampaignsStatistic;
