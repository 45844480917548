import { combineReducers } from 'redux';
import sessionReducer from './sessionReducer';
import serverReducer from './serverReducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  server: serverReducer,
});

export default rootReducer;
