import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { colors, Typography } from '@material-ui/core';
import Label from 'src/components/Devias/Label';
import clsx from 'clsx';
import { defaultBrandOverview } from '../schema';

const useStyles = makeStyles((theme) => ({
  root: {},
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  }
}));

function BrandOverview({ className, brandOverview }) {
  const classes = useStyles();
  return (
    <>
      <Typography
        component="h2"
        gutterBottom
        variant="overline"
        className={clsx(classes.root, className)}
      >
        {brandOverview.name}
      </Typography>
      <div className={classes.valueContainer}>
        <Typography variant="h3">
          {brandOverview.totalSubscriber}
        </Typography>
        {brandOverview.percentage > 0 && (
          <Label
            className={classes.label}
            color={brandOverview.percentage > 0.2 ? colors.green[600] : colors.yellow[600]}
            variant="contained"
          >
            {brandOverview.percentage > 0 ? `${Math.round(brandOverview.percentage * 100).toFixed(2)}%` : '0%'}
          </Label>
        )}
      </div>
    </>
  );
}

BrandOverview.propTypes = {
  className: PropTypes.string,
  brandOverview: PropTypes.object
};

BrandOverview.defaultProps = {
  brandOverview: defaultBrandOverview()
};

export default BrandOverview;
