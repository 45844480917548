import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Line } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/styles';
import { subscriberByDayDataSet } from '../dashboard';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative'
  }
}));

function Chart({ statByDay, labels, className, ...rest }) {
  const classes = useStyles();
  const theme = useTheme();

  const [chartData, setChartData] = useState({
    datasets: [subscriberByDayDataSet()],
    labels
  });

  useEffect(() => {
    if (statByDay) {
      setChartData({
        datasets: statByDay.map((statByDayItem) => subscriberByDayDataSet(statByDayItem)),
        labels
      });
    }
    return () => {
    };
  }, [statByDay]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cornerRadius: 20,
    legend: {
      display: false
    },
    layout: {
      padding: 0
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            maxTicksLimit: 5,
          }
        }
      ]
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.common.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        title: () => {},
        label: (tooltipItem) => (`${chartData.datasets[tooltipItem.datasetIndex].label || ''}: ${tooltipItem.yLabel}`)
      }
    }
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Line
        data={chartData}
        options={options}
      />
    </div>
  );
}

Chart.propTypes = {
  className: PropTypes.string,
  statByDay: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired
};

export default Chart;
