import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, Grid, Typography } from '@material-ui/core';
import { requestBrandOverviews } from '../api';
import BrandOverview from './BrandOverview';

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  totalSubscribersContainer: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(3)
  },
  totalSubscribersContainerThisMonth: {
    color: theme.palette.primary.contrastText
  },
  totalSubscribers: {
    color: theme.palette.primary.contrastText
  }
}));

function Overview({ className, ...rest }) {
  const classes = useStyles();

  const [brandOverviews, setBrandOverviews] = useState([]);

  useEffect(() => {
    let mounted = true;

    const fetchEntities = () => {
      requestBrandOverviews((response) => {
        if (mounted) {
          setBrandOverviews(response.data);
        }
      });
    };

    fetchEntities();

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        // alignItems="center"
        container
        justify="space-between"
      >
        <Grid
          className={clsx(classes.item, classes.totalSubscribersContainer)}
          item
          md={3}
          sm={6}
          xs={12}
        >
          <Typography
            component="h4"
            gutterBottom
            variant="h4"
            className={classes.totalSubscribers}
          >
            New subscribers
          </Typography>
          <Typography
            variant="h4"
            className={classes.totalSubscribersContainerThisMonth}
          >
            this month
          </Typography>
        </Grid>
        {brandOverviews && brandOverviews.map((brandOverview) => (
          <Grid
            className={classes.item}
            item
            key={brandOverview.id}
            md={3}
            sm={6}
            xs={12}
          >
            <BrandOverview brandOverview={brandOverview}/>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
}

Overview.propTypes = {
  className: PropTypes.string
};

export default Overview;
