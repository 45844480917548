import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { theme, themeWithRtl } from './theme';
import { configureStore } from './store';
import routes from './routes';
import ScrollReset from './components/Devias/ScrollReset';
import StylesProvider from './components/Devias/StylesProvider';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
// import './mock';
import './assets/scss/main.scss';
import renderProtectedRoutes from './utils/renderProtectedRoutes';
import UserSession from './utils/UserSession';

const history = createBrowserHistory();
const store = configureStore();

function App() {
  const [direction] = useState('ltr');

  // const handleDirecitonToggle = () => {
  //   setDirection((prevDirection) => (prevDirection === 'ltr' ? 'rtl' : 'ltr'));
  // };

  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={direction === 'rtl' ? themeWithRtl : theme}>
        <StylesProvider direction={direction}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={history}>
              <ScrollReset />
              {/* <GoogleAnalytics /> */}
              {/* <CookiesNotification /> */}
              {/* <DirectionToggle */}
              {/*  direction={direction} */}
              {/*  onToggle={handleDirecitonToggle} */}
              {/* /> */}
              {renderProtectedRoutes(routes, UserSession.getToken(), '/auth/login')}
            </Router>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    </StoreProvider>
  );
}

export default App;
