import { colors } from '@material-ui/core';

export const tagColorNames = {
  AMBER: 'Amber',
  BLUE: 'Blue',
  BLUE_GREY: 'Blue Grey',
  BROWN: 'Brown',
  CYAN: 'Cyan',
  DEEP_ORANGE: 'Deep Orange',
  DEEP_PURPLE: 'Deep Purple',
  GREEN: 'Green',
  GREY: 'Grey',
  INDIGO: 'Indigo',
  LIGHT_BLUE: 'Light Blue',
  LIGHT_GREEN: 'Light Green',
  LIME: 'Lime',
  ORANGE: 'Orange',
  PINK: 'Pink',
  PURPLE: 'Purple',
  RED: 'Red',
  TEAL: 'Teal',
  YELLOW: 'Yellow'
};

export const TagColor = {
  AMBER: colors.amber[600],
  BLUE: colors.blue[600],
  BLUE_GREY: colors.blueGrey[600],
  BROWN: colors.brown[600],
  CYAN: colors.cyan[600],
  DEEP_ORANGE: colors.deepOrange[600],
  DEEP_PURPLE: colors.deepPurple[600],
  GREEN: colors.green[600],
  GREY: colors.grey[600],
  INDIGO: colors.indigo[600],
  LIGHT_BLUE: colors.lightBlue[600],
  LIGHT_GREEN: colors.lightGreen[600],
  LIME: colors.lime[600],
  ORANGE: colors.orange[600],
  PINK: colors.pink[600],
  PURPLE: colors.purple[600],
  RED: colors.red[600],
  TEAL: colors.teal[600],
  YELLOW: colors.yellow[600],
};
