export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

export const showLoading = () => (dispatch) => dispatch({
  type: SHOW_LOADING
});

export const hideLoading = () => (dispatch) => dispatch({
  type: HIDE_LOADING
});
