import axios from '../../../../utils/axios';

const ACTION_LOAD_BRAND_OVERVIEW = 'requestBrandOverviews';
const ACTION_LOAD_SUBSCRIBER_OVERVIEW = 'requestSubscriberOverviews';

function handleException(action, error, failureProcessor) {
  if (!error.response || !error.response.data || !failureProcessor) {
    return;
  }
  const { data } = error.response;

  let errorMessage = data.reason;
  if (action === ACTION_LOAD_BRAND_OVERVIEW) {
    errorMessage = 'Cannot load brand overview.';
  } else if (action === ACTION_LOAD_SUBSCRIBER_OVERVIEW) {
    errorMessage = 'Cannot load subscriber overview.';
  }
  failureProcessor(errorMessage, data);
}

export const requestBrandOverviews = (responseProcessor, failureProcessor = null) => {
  axios.get('/api/dashboards/brandOverviews/').then((response) => {
    responseProcessor(response);
  }).catch((error) => {
    handleException(ACTION_LOAD_BRAND_OVERVIEW, error, failureProcessor);
  });
};

export const requestSubscriberOverviews = (responseProcessor, failureProcessor = null) => {
  axios.get('/api/dashboards/subscriberOverviews/').then((response) => {
    responseProcessor(response);
  }).catch((error) => {
    handleException(ACTION_LOAD_SUBSCRIBER_OVERVIEW, error, failureProcessor);
  });
};
