import UserSession from '../utils/UserSession';

export const dashboardPermissions = () => ([
  'AEMS_DASHBOARD_READ_PERM', 'AEMS_DASHBOARD_WRITE_PERM', 'AEMS_DASHBOARD_ADMIN_PERM'
]);

export const mailTemplatePermissions = () => ([
  'AEMS_MAIL_TEMPLATE_READ_PERM', 'AEMS_MAIL_TEMPLATE_WRITE_PERM', 'AEMS_MAIL_TEMPLATE_ADMIN_PERM'
]);
export const mailTemplateWritePermissions = () => ([
  'AEMS_MAIL_TEMPLATE_WRITE_PERM', 'AEMS_MAIL_TEMPLATE_ADMIN_PERM'
]);
export const hasTemplateEditPermission = () => UserSession.hasPermissions(['AEMS_MAIL_TEMPLATE_WRITE_PERM', 'AEMS_MAIL_TEMPLATE_ADMIN_PERM']);
export const hasTemplateAdminPermission = () => UserSession.hasPermissions(['AEMS_MAIL_TEMPLATE_ADMIN_PERM']);

export const mailDeliveryPermissions = () => ([
  'AEMS_CAMPAIGN_READ_PERM', 'AEMS_CAMPAIGN_WRITE_PERM', 'AEMS_CAMPAIGN_ADMIN_PERM'
]);
export const mailDeliveryWritePermissions = () => ([
  'AEMS_CAMPAIGN_WRITE_PERM', 'AEMS_CAMPAIGN_ADMIN_PERM'
]);

export const campaignPermissions = () => ([
  'AEMS_CAMPAIGN_READ_PERM', 'AEMS_CAMPAIGN_WRITE_PERM', 'AEMS_CAMPAIGN_ADMIN_PERM'
]);
export const campaignWritePermissions = () => ([
  'AEMS_CAMPAIGN_WRITE_PERM', 'AEMS_CAMPAIGN_ADMIN_PERM'
]);
export const campaignAdminPermissions = () => ([
  'AEMS_CAMPAIGN_ADMIN_PERM'
]);
export const hasCampaignEditPermission = () => UserSession.hasPermissions(['AEMS_CAMPAIGN_WRITE_PERM', 'AEMS_CAMPAIGN_ADMIN_PERM']);
export const hasCampaignAdminPermission = () => UserSession.hasPermissions(['AEMS_CAMPAIGN_ADMIN_PERM']);

export const campaignSystemPermissions = () => ([
  'AEMS_CAMPAIGN_SYSTEM_READ_PERM', 'AEMS_CAMPAIGN_SYSTEM_WRITE_PERM', 'AEMS_CAMPAIGN_SYSTEM_ADMIN_PERM'
]);
export const campaignSystemWritePermissions = () => ([
  'AEMS_CAMPAIGN_SYSTEM_WRITE_PERM', 'AEMS_CAMPAIGN_SYSTEM_ADMIN_PERM'
]);
export const hasCampaignSystemEditPermission = () => UserSession.hasPermissions(['AEMS_CAMPAIGN_SYSTEM_WRITE_PERM', 'AEMS_CAMPAIGN_SYSTEM_ADMIN_PERM']);

export const ticketPermissions = () => ([
  'AEMS_TICKET_READ_PERM', 'AEMS_TICKET_WRITE_PERM', 'AEMS_TICKET_ADMIN_PERM'
]);
export const hasTicketAdminPermission = () => UserSession.hasPermissions(['AEMS_TICKET_ADMIN_PERM']);

export const subscriberPermissions = () => ([
  'AEMS_SUBSCRIBER_READ_PERM', 'AEMS_SUBSCRIBER_WRITE_PERM', 'AEMS_SUBSCRIBER_ADMIN_PERM'
]);
export const hasSubscriberEditPermission = () => UserSession.hasPermissions(['AEMS_SUBSCRIBER_WRITE_PERM', 'AEMS_SUBSCRIBER_ADMIN_PERM']);
export const hasSubscriberAdminPermission = () => UserSession.hasPermissions(['AEMS_SUBSCRIBER_ADMIN_PERM']);

export const tagPermissions = () => ([
  'AEMS_TAG_READ_PERM', 'AEMS_TAG_WRITE_PERM', 'AEMS_TAG_ADMIN_PERM'
]);
export const tagWritePermissions = () => ([
  'AEMS_TAG_WRITE_PERM', 'AEMS_TAG_ADMIN_PERM'
]);
export const hasTagEditPermission = () => UserSession.hasPermissions(['AEMS_TAG_WRITE_PERM', 'AEMS_TAG_ADMIN_PERM']);
export const hasTagAdminPermission = () => UserSession.hasPermissions(['AEMS_TAG_ADMIN_PERM']);

export const userProfilePermissions = () => ([
  'AEMS_USER_READ_PERM', 'AEMS_USER_WRITE_PERM', 'AEMS_USER_ADMIN_PERM'
]);
export const companyPermissions = () => ([
  'AEMS_COMPANY_READ_PERM', 'AEMS_COMPANY_WRITE_PERM', 'AEMS_COMPANY_ADMIN_PERM'
]);
export const companyAdminPermissions = () => ([
  'AEMS_COMPANY_ADMIN_PERM'
]);

export const brandPermissions = () => ([
  'AEMS_BRAND_READ_PERM', 'AEMS_BRAND_WRITE_PERM', 'AEMS_BRAND_ADMIN_PERM'
]);
export const brandAdminPermissions = () => ([
  'AEMS_BRAND_ADMIN_PERM'
]);

export const hasBrandEditPermission = () => UserSession.hasPermissions(['AEMS_BRAND_WRITE_PERM', 'AEMS_BRAND_ADMIN_PERM']);
export const hasBrandAdminPermission = () => UserSession.hasPermissions(['AEMS_BRAND_ADMIN_PERM']);

export const companyAndBrandPermissions = () => {
  const permissions = [];
  companyAdminPermissions().forEach((perm) => permissions.push(perm));
  brandAdminPermissions().forEach((perm) => permissions.push(perm));
  return permissions;
};
