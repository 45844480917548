import * as actionTypes from 'src/actions';

const initialState = {
  showLoading: false,
};

const serverReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_LOADING: {
      return {
        showLoading: true
      };
    }

    case actionTypes.HIDE_LOADING: {
      return {
        showLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default serverReducer;
