import axios from '../../../../utils/axios';
import { parseTags } from '../../../aipms/Management/Tag/api';
import { CAMPAIGN_TRIGGER_ACTION_SAVE } from './campaign';
import { ARCHIVED, UN_ARCHIVED } from '../EmailTemplate/template';

const ACTION_SEARCH_CAMPAIGN = 'requestCampaigns';
const ACTION_SEARCH_CAMPAIGN_STAT = 'requestCampaignsStatistic';
const ACTION_LOAD_CAMPAIGN = 'requestLoadCampaign';
const ACTION_DELETE_CAMPAIGN = 'requestDeleteCampaigns';
const ACTION_ARCHIVE_CAMPAIGN = 'requestArchiveCampaigns';
const ACTION_UPDATE_CAMPAIGN = 'requestUpdateCampaign';
const ACTION_UPDATE_CAMPAIGN_MAIL = 'requestUpdateCampaignMail';
const ACTION_TRIGGER_CAMPAIGN = 'requestTriggerCampaign';
const ACTION_CREATE_CAMPAIGN = 'requestCreateCampaign';
const ACTION_CREATE_CAMPAIGN_MAIL = 'requestCreateCampaignMail';
const ACTION_ADD_SUBSCRIBERS = 'requestAddSubscribers';

const ACTION_SEARCH_CAMPAIGN_EXECUTION = 'requestCampaignExecutions';
const ACTION_EXPORT_CAMPAIGN = 'exportCampaigns';

const ACTION_PREPARE_PREVIEW = 'requestPreparePreview';

function handleException(action, error, failureProcessor) {
  if (!error.response || !error.response.data || !failureProcessor) {
    return;
  }
  const { data } = error.response;

  let errorMessage = data.reason;
  if (action === ACTION_SEARCH_CAMPAIGN) {
    errorMessage = 'Cannot search campaigns.';
  } else if (action === ACTION_SEARCH_CAMPAIGN_STAT) {
    errorMessage = 'Cannot search campaign statistic.';
  } else if (action === ACTION_LOAD_CAMPAIGN) {
    errorMessage = 'Cannot load campaign.';
  } else if (action === ACTION_DELETE_CAMPAIGN) {
    if (data.errorCode === 'entity.isBeingUsed') {
      errorMessage = 'campaign(s) is being used';
    } else {
      errorMessage = data.reason ? data.reason : 'Cannot delete campaign(s).';
    }
  } else if (action === ACTION_UPDATE_CAMPAIGN || action === ACTION_UPDATE_CAMPAIGN_MAIL) {
    errorMessage = 'Cannot update campaign.';
  } else if (action === ACTION_TRIGGER_CAMPAIGN) {
    errorMessage = 'Cannot update campaign.';
  } else if (action === ACTION_ARCHIVE_CAMPAIGN) {
    errorMessage = 'Cannot archive campaign.';
  } else if (action === ACTION_CREATE_CAMPAIGN) {
    errorMessage = 'Cannot create campaign.';
  } else if (action === ACTION_CREATE_CAMPAIGN_MAIL) {
    errorMessage = 'Cannot create campaign.';
  } else if (action === ACTION_SEARCH_CAMPAIGN_EXECUTION) {
    errorMessage = 'Cannot search campaign execution.';
  } else if (action === ACTION_PREPARE_PREVIEW) {
    errorMessage = 'Cannot prepare preview.';
  }
  failureProcessor(errorMessage, data);
}

// eslint-disable-next-line max-len
export const requestCampaignExecutions = (campaignId, filterUrl, respProcessor, failureProcessor = null) => {
  axios.get(`/api/campaigns/${campaignId}/campaignExecutions/${filterUrl || ''}`).then((response) => {
    respProcessor(response);
  }).catch((error) => {
    handleException(ACTION_SEARCH_CAMPAIGN_EXECUTION, error, failureProcessor);
  });
};

export const exportCampaigns = (filterUrl, responseProcessor, failureProcessor = null) => {
  axios.get(`/api/campaigns/export-excel${filterUrl || ''}`, { responseType: 'blob' }).then((response) => {
    const contentType = response.headers['content-type'];
    const blob = new Blob([response.data], { type: contentType, encoding: 'UTF-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'campaigns.xlsx';
    link.click();
    responseProcessor();
  }).catch((error) => {
    handleException(ACTION_EXPORT_CAMPAIGN, error, failureProcessor);
  });
};

export const requestCampaigns = (filterUrl, responseProcessor, failureProcessor = null) => {
  axios.get(`/api/campaigns/${filterUrl || ''}`).then((response) => {
    responseProcessor(response);
  }).catch((error) => {
    handleException(ACTION_SEARCH_CAMPAIGN, error, failureProcessor);
  });
};

export const requestCampaignsStatistic = (filterUrl, responseProcessor, failureProcessor = null) => {
  axios.get(`/api/campaigns/statistics/${filterUrl || ''}`).then((response) => {
    responseProcessor(response);
  }).catch((error) => {
    handleException(ACTION_SEARCH_CAMPAIGN_STAT, error, failureProcessor);
  });
};

export const requestLoadCampaign = (campaignId, responseProcessor, failureProcessor = null) => {
  axios.get(`/api/campaigns/${campaignId}`).then((response) => {
    responseProcessor(response);
  }).catch((error) => {
    handleException(ACTION_LOAD_CAMPAIGN, error, failureProcessor);
  });
};

export const requestDeleteCampaigns = (entityIds, respProcessor, failureProcessor = null) => {
  axios.delete('/api/campaigns/', {
    data: {
      ids: entityIds
    }
  }).then((response) => {
    respProcessor(response);
  }).catch((error) => {
    handleException(ACTION_DELETE_CAMPAIGN, error, failureProcessor);
  });
};

export const requestArchiveCampaigns = (entityIds, archive, respProcessor, failureProcessor = null) => {
  axios.put('/api/campaigns/archive', {
    ids: entityIds,
    action: archive ? ARCHIVED : UN_ARCHIVED
  }).then((response) => {
    respProcessor(response);
  }).catch((error) => {
    handleException(ACTION_ARCHIVE_CAMPAIGN, error, failureProcessor);
  });
};

function parseToRequestEntity(campaignInfo) {
  const tags = parseTags(campaignInfo.tags);
  const tagsSubscriber = parseTags(campaignInfo.dataSourceSubscriberTags);
  return {
    ...campaignInfo,
    enabled: campaignInfo.enabled === 'TRUE',
    subscriberTagIds: tagsSubscriber.tagIds,
    newTagsSubscriber: tagsSubscriber.newTags,
    tagIds: tags.tagIds,
    newTags: tags.newTags
  };
}

export const requestUpdateCampaign = (campaignInfo, respProcessor, failureProcessor = null) => {
  /* Convert detail subscriber info to saveSubscriberDTO */
  const data = parseToRequestEntity(campaignInfo);
  axios.put('/api/campaigns/', data).then((response) => {
    respProcessor(response);
  }).catch((error) => {
    handleException(ACTION_UPDATE_CAMPAIGN, error, failureProcessor);
  });
};
export const requestCreateCampaign = (campaignInfo, respProcessor, failureProcessor = null) => {
  const data = parseToRequestEntity(campaignInfo);
  axios.post('/api/campaigns/', data).then((response) => {
    respProcessor(response);
  }).catch((error) => {
    handleException(ACTION_CREATE_CAMPAIGN, error, failureProcessor);
  });
};

export const requestUpdateCampaignMail = (campaign, respProcessor, failureProcessor = null) => {
  const tags = parseTags(campaign.tags);
  const tagsSubscriber = parseTags(campaign.dataSourceSubscriberTags);
  const data = {
    ...campaign,
    enabled: campaign.enabled === 'TRUE',
    subscriberTagIds: tagsSubscriber.tagIds,
    newTagsSubscriber: tagsSubscriber.newTags,
    tagIds: tags.tagIds,
    newTags: tags.newTags
  };
  axios.put('/api/campaigns/mails/', data).then((response) => {
    respProcessor(response);
  }).catch((error) => {
    handleException(ACTION_UPDATE_CAMPAIGN_MAIL, error, failureProcessor);
  });
};
export const requestCreateCampaignMail = (campaign, respProcessor, failureProcessor = null) => {
  const tags = parseTags(campaign.tags);
  const tagsSubscriber = parseTags(campaign.dataSourceSubscriberTags);
  const data = {
    ...campaign,
    brandId: campaign.brand.id,
    mailTemplateId: campaign.mailTemplate.id,
    submitAction: CAMPAIGN_TRIGGER_ACTION_SAVE,
    subscriberTagIds: tagsSubscriber.tagIds,
    newTagsSubscriber: tagsSubscriber.newTags,
    tagIds: tags.tagIds,
    newTags: tags.newTags
  };
  axios.post('/api/campaigns/mails/', data).then((response) => {
    respProcessor(response);
  }).catch((error) => {
    handleException(ACTION_CREATE_CAMPAIGN_MAIL, error, failureProcessor);
  });
};

export const requestTriggerCampaign = (request, respProcessor, failureProcessor = null) => {
  axios.put(`/api/campaigns/${request.id}/triggers`, request).then((response) => {
    respProcessor(response);
  }).catch((error) => {
    handleException(ACTION_TRIGGER_CAMPAIGN, error, failureProcessor);
  });
};

export const requestAddSubscribers = (campaignId, emails, respProcessor, failureProcessor = null) => {
  const data = emails ? emails.split(';') : [];
  axios.post(`/api/campaigns/${campaignId}/subscribers/`, data).then((response) => {
    respProcessor(response);
  }).catch((error) => {
    handleException(ACTION_ADD_SUBSCRIBERS, error, failureProcessor);
  });
};

export const requestCampaignStrategies = (brandId, strategy, respProcessor, failureProcessor = null) => {
  if (!brandId) {
    return;
  }
  axios.get(`/api/campaigns/strategies/${strategy}/brands/${brandId}`).then((response) => {
    respProcessor(response);
  }).catch((error) => {
    handleException('requestCampaignStrategies', error, failureProcessor);
  });
};

export const requestPreparePreview = (
  theMailTemplateId, theHtmlBodyContent, theHtmlBannerContent, thePreviewId, respProcessor, failureProcessor = null
) => {
  const data = {
    mailTemplateId: theMailTemplateId,
    previewId: thePreviewId,
    htmlBodyContent: theHtmlBodyContent,
    htmlBannerContent: theHtmlBannerContent,
  };
  const requester = thePreviewId ? axios.put(`/api/campaigns/previews/${thePreviewId}`, data)
    : axios.post('/api/campaigns/previews', data);

  requester.then((response) => {
    respProcessor(response);
  }).catch((error) => {
    handleException(ACTION_PREPARE_PREVIEW, error, failureProcessor);
  });
};
