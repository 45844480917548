import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import Chart from './Chart';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  chart: {
    padding: theme.spacing(4, 2, 0, 2),
    height: 400
  }
}));

function SubscriberOverview({ className, statByDay, statByDayLabel, ...rest }) {
  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        // action={<GenericMoreButton />}
        title="Subscriber's Daily Statistic"
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Chart
              className={classes.chart}
              statByDay={statByDay}
              labels={statByDayLabel}
            />
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
}

SubscriberOverview.propTypes = {
  className: PropTypes.string,
  statByDay: PropTypes.array,
  statByDayLabel: PropTypes.array
};

export default SubscriberOverview;
