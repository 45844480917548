const UserSession = (function() {
  let profile = null;
  // const filterValues = [];
  // const pagination = [];
  // const sortInfo = [];

  const getToken = () => window.localStorage.getItem('aems_token');
  const setToken = (tokenValue) => {
    window.localStorage.setItem('aems_token', tokenValue);
  };
  const logout = () => {
    window.localStorage.removeItem('aems_token');
    window.localStorage.removeItem('aems_profile');
  };

  const getProfile = () => {
    if (!profile) {
      const storedProfile = window.localStorage.getItem('aems_profile');
      if (storedProfile) {
        profile = JSON.parse(storedProfile);
      }
    }
    return profile;
  };
  const setProfile = (value) => {
    window.localStorage.setItem('aems_profile', JSON.stringify(value));
  };

  const hasPermissions = (perms = []) => {
    let hasPerm = false;
    perms.forEach((perm) => {
      if (getProfile().permissions && getProfile().permissions.includes(perm)) {
        hasPerm = true;
      }
    });
    return hasPerm;
  };

  const getFilters = (filterName, defaultFilter) => {
    // if (!filterValues[`aems_${filterName}_filter`]) {
    //   const storedFilterValues = window.localStorage.getItem(`aems_${filterName}_filter`);
    //   if (storedFilterValues && storedFilterValues !== 'null' && storedFilterValues !== null) {
    //     filterValues[`aems_${filterName}_filter`] = JSON.parse(storedFilterValues);
    //   } else {
    //     filterValues[`aems_${filterName}_filter`] = defaultFilter;
    //   }
    // }
    // return filterValues[`aems_${filterName}_filter`];
    return defaultFilter;
  };
  const setFilters = (filterName, theFilterValues) => {
    // filterValues[`aems_${filterName}_filter`] = theFilterValues;
    // window.localStorage.setItem(`aems_${filterName}_filter`, JSON.stringify(theFilterValues));
  };

  const getPagination = (filterName, defaultPagination) => {
    // try {
    //   if (!pagination[`aems_${filterName}_pagination`]) {
    //     const storedFilterValues = window.localStorage.getItem(`aems_${filterName}_pagination`);
    //     if (storedFilterValues && storedFilterValues !== 'null' && storedFilterValues !== null) {
    //       pagination[`aems_${filterName}_pagination`] = JSON.parse(storedFilterValues);
    //     } else {
    //       pagination[`aems_${filterName}_pagination`] = defaultPagination;
    //     }
    //   }
    // } catch (err) {
    //   pagination[`aems_${filterName}_pagination`] = defaultPagination;
    // }
    //
    // return pagination[`aems_${filterName}_pagination`];
    return defaultPagination;
  };
  const setPagination = (filterName, thePagination) => {
    // pagination[`aems_${filterName}_pagination`] = thePagination;
    // window.localStorage.setItem(`aems_${filterName}_pagination`, JSON.stringify(thePagination));
  };

  const getSortInfo = (filterName, defaultSortInfo) => {
    // try {
    //   if (!sortInfo[`aems_${filterName}_sortInfo`]) {
    //     const storedFilterValues = window.localStorage.getItem(`aems_${filterName}_sortInfo`);
    //     if (storedFilterValues && storedFilterValues !== 'null' && storedFilterValues !== null) {
    //       sortInfo[`aems_${filterName}_sortInfo`] = JSON.parse(storedFilterValues);
    //     } else {
    //       sortInfo[`aems_${filterName}_sortInfo`] = defaultSortInfo;
    //     }
    //   }
    // } catch (err) {
    //   sortInfo[`aems_${filterName}_sortInfo`] = defaultSortInfo;
    // }
    // return sortInfo[`aems_${filterName}_sortInfo`];
    return defaultSortInfo
  };
  const setSortInfo = (filterName, theSortInfo) => {
    // sortInfo[`aems_${filterName}_sortInfo`] = theSortInfo;
    // window.localStorage.setItem(`aems_${filterName}_sortInfo`, JSON.stringify(theSortInfo));
  };

  return {
    logout,
    getToken,
    setToken,
    getProfile,
    setProfile,
    hasPermissions,
    getFilters,
    setFilters,
    getPagination,
    setPagination,
    getSortInfo,
    setSortInfo,
  };
}());

export default UserSession;
