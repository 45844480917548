// eslint-disable-next-line no-unused-vars
import React from 'react';
import BarChartIcon from '@material-ui/icons/BarChart';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import { BusinessOutlined } from '@material-ui/icons';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import {
  campaignAdminPermissions,
  campaignPermissions, campaignSystemWritePermissions,
  campaignWritePermissions,
  companyAndBrandPermissions,
  dashboardPermissions,
  mailDeliveryWritePermissions,
  mailTemplateWritePermissions,
  subscriberPermissions,
  tagWritePermissions,
  ticketPermissions,
  userProfilePermissions
} from '../../aems/permissions';

export default [
  {
    subheader: 'AEMS',
    items: [
      {
        title: 'Dashboards',
        href: '/dashboards/default',
        icon: DashboardIcon,
        permissions: dashboardPermissions()
      },
      {
        title: 'Email Marketing',
        href: '/marketing',
        icon: BusinessOutlined,
        items: [
          {
            title: 'Campaign Management',
            href: '/aems-marketing/campaigns',
            permissions: campaignPermissions()
          },
          {
            title: 'System Campaign',
            href: '/aems-marketing/system-campaigns',
            permissions: campaignSystemWritePermissions()
          },
          {
            title: 'Mail Delivery',
            href: '/aems-marketing/mail-deliveries',
            permissions: mailDeliveryWritePermissions()
          }
        ]
      },
      // {
      //   title: 'Customer Service',
      //   href: '/aems-marketing/supports',
      //   icon: LiveHelpOutlinedIcon,
      //   items: [
      //     {
      //       title: 'Ticket Management',
      //       href: '/aems-marketing/tickets',
      //       permissions: ticketPermissions()
      //     }
      //     // {
      //     //   title: 'Your Assigned Ticket',
      //     //   href: '/aems-marketing/my-tickets'
      //     // }
      //   ]
      // },
      {
        title: 'Management',
        href: '/management',
        icon: BarChartIcon,
        items: [
          {
            title: 'Email Template',
            href: '/aems-marketing/email-templates',
            permissions: mailTemplateWritePermissions()
          },
          {
            title: 'Tag',
            href: '/aems-management/tags',
            permissions: tagWritePermissions()
          },
          {
            title: 'Customer',
            href: '/aems-management/customers',
            permissions: subscriberPermissions()
          }
        ]
      },
      {
        title: 'Settings',
        href: '/aems-settings',
        icon: SettingsIcon,
        items: [
          {
            title: 'Account',
            href: '/aems-settings/user-profile',
            permissions: userProfilePermissions()
          },
          {
            title: 'Company',
            href: '/aems-settings/company',
            permissions: companyAndBrandPermissions()
          }
          // {
          //   title: 'Application',
          //   href: '/aems-settings/application'
          // }
        ]
      }
    ]
  }
  // {
  //   subheader: 'Pages',
  //   items: [
  //     {
  //       title: 'Overview',
  //       href: '/overview',
  //       icon: HomeIcon
  //     },
  //     {
  //       title: 'Dashboards',
  //       href: '/dashboards',
  //       icon: DashboardIcon,
  //       items: [
  //         {
  //           title: 'Default',
  //           href: '/dashboards/default'
  //         },
  //         {
  //           title: 'Analytics',
  //           href: '/dashboards/analytics'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Management',
  //       href: '/management',
  //       icon: BarChartIcon,
  //       items: [
  //         {
  //           title: 'Customers',
  //           href: '/management/customers'
  //         },
  //         {
  //           title: 'Customer Details',
  //           href: '/management/customers/1/summary'
  //         },
  //         {
  //           title: 'Projects',
  //           href: '/management/projects'
  //         },
  //         {
  //           title: 'Orders',
  //           href: '/management/orders'
  //         },
  //         {
  //           title: 'Order Details',
  //           href: '/management/orders/1'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Social Feed',
  //       href: '/social-feed',
  //       icon: PeopleIcon
  //     },
  //     {
  //       title: 'Profile',
  //       href: '/profile',
  //       icon: PersonIcon,
  //       items: [
  //         {
  //           title: 'Timeline',
  //           href: '/profile/1/timeline'
  //         },
  //         {
  //           title: 'Connections',
  //           href: '/profile/1/connections'
  //         },
  //         {
  //           title: 'Projects',
  //           href: '/profile/1/projects'
  //         },
  //         {
  //           title: 'Reviews',
  //           href: '/profile/1/reviews'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Project',
  //       href: '/projects',
  //       icon: FolderIcon,
  //       items: [
  //         {
  //           title: 'Browse',
  //           href: '/projects'
  //         },
  //         {
  //           title: 'Create',
  //           href: '/projects/create'
  //         },
  //         {
  //           title: 'Overview',
  //           href: '/projects/1/overview'
  //         },
  //         {
  //           title: 'Files',
  //           href: '/projects/1/files'
  //         },
  //         {
  //           title: 'Activity',
  //           href: '/projects/1/activity'
  //         },
  //         {
  //           title: 'Subscribers',
  //           href: '/projects/1/subscribers'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Invoice',
  //       href: '/invoices/1',
  //       icon: ReceiptIcon
  //     },
  //     {
  //       title: 'Kanban Board',
  //       href: '/kanban-board',
  //       icon: ListAltIcon
  //     },
  //     {
  //       title: 'Mail',
  //       href: '/mail',
  //       icon: MailIcon,
  //       label: () => (
  //         <Label
  //           color={colors.red[500]}
  //           shape="rounded"
  //         >
  //           2
  //         </Label>
  //       )
  //     },
  //     {
  //       title: 'Chat',
  //       href: '/chat',
  //       icon: ChatIcon,
  //       label: () => (
  //         <Label
  //           color={colors.red[500]}
  //           shape="rounded"
  //         >
  //           4
  //         </Label>
  //       )
  //     },
  //     {
  //       title: 'Calendar',
  //       href: '/calendar',
  //       icon: CalendarTodayIcon,
  //       label: () => <Label color={colors.green[500]}>New</Label>
  //     },
  //     {
  //       title: 'Settings',
  //       href: '/settings',
  //       icon: SettingsIcon,
  //       items: [
  //         {
  //           title: 'General',
  //           href: '/settings/general'
  //         },
  //         {
  //           title: 'Subscription',
  //           href: '/settings/subscription'
  //         },
  //         {
  //           title: 'Notifications',
  //           href: '/settings/notifications'
  //         },
  //         {
  //           title: 'Security',
  //           href: '/settings/security'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Authentication',
  //       href: '/auth',
  //       icon: LockOpenIcon,
  //       items: [
  //         {
  //           title: 'Login',
  //           href: '/auth/login'
  //         },
  //         {
  //           title: 'Register',
  //           href: '/auth/register'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Errors',
  //       href: '/errors',
  //       icon: ErrorIcon,
  //       items: [
  //         {
  //           title: 'Error 401',
  //           href: '/errors/error-401'
  //         },
  //         {
  //           title: 'Error 404',
  //           href: '/errors/error-404'
  //         },
  //         {
  //           title: 'Error 500',
  //           href: '/errors/error-500'
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Support',
  //   items: [
  //     {
  //       title: 'Components',
  //       href: '/components',
  //       icon: ViewConfigIcon,
  //       items: [
  //         {
  //           title: 'Buttons',
  //           href: '/components/buttons'
  //         },
  //         {
  //           title: 'Cards',
  //           href: '/components/cards'
  //         },
  //         {
  //           title: 'Chips',
  //           href: '/components/chips'
  //         },
  //         {
  //           title: 'Lists',
  //           href: '/components/lists'
  //         },
  //         {
  //           title: 'Forms',
  //           href: '/components/forms'
  //         },
  //         {
  //           title: 'Modals',
  //           href: '/components/modals'
  //         },
  //         {
  //           title: 'Typography',
  //           href: '/components/typography'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Presentation',
  //       href: '/presentation',
  //       icon: PresentToAllIcon
  //     },
  //     {
  //       title: 'Getting started',
  //       href: '/getting-started',
  //       icon: CodeIcon
  //     },
  //     {
  //       title: 'Changelog',
  //       href: '/changelog',
  //       icon: ListIcon,
  //       label: () => <Label color={colors.blue['500']}>v1.3.0</Label>
  //     }
  //   ]
  // }
];
