/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Button, colors, Hidden, IconButton, LinearProgress, Toolbar } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import axios from 'src/utils/axios';
import NotificationsPopover from 'src/components/NotificationsPopover';
import PricingModal from 'src/components/Devias/PricingModal';
import ChatBar from './ChatBar';
import UserSession from '../../utils/UserSession';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  logo: {
    // width: '170px',
    height: '36px'
  },
  flexGrow: {
    flexGrow: 1
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  trialIcon: {
    marginRight: theme.spacing(1)
  },
  menuButton: {
    marginRight: theme.spacing(1)
  },
  chatButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  }
}));

const popularSearches = [
  'Devias React Dashboard',
  'Devias',
  'Admin Pannel',
  'Project',
  'Pages'
];

function TopBar({
  onOpenNavBarMobile,
  className,
  ...rest
}) {
  const classes = useStyles();
  const history = useHistory();
  const searchRef = useRef(null);

  const showLoading = useSelector((state) => state.server.showLoading);

  const dispatch = useDispatch();
  const notificationsRef = useRef(null);

  const [openSearchPopover, setOpenSearchPopover] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openChatBar, setOpenChatBar] = useState(false);
  const [pricingModalOpen, setPricingModalOpen] = useState(false);

  const handleLogout = () => {
    UserSession.logout();
    history.push('/auth/login');
    // dispatch(logout());
  };

  const handlePricingModalOpen = () => {
    setPricingModalOpen(true);
  };

  const handlePricingModalClose = () => {
    setPricingModalOpen(false);
  };

  const handleChatBarOpen = () => {
    setOpenChatBar(true);
  };

  const handleChatBarClose = () => {
    setOpenChatBar(false);
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);

    if (event.target.value) {
      if (!openSearchPopover) {
        setOpenSearchPopover(true);
      }
    } else {
      setOpenSearchPopover(false);
    }
  };

  const handleSearchPopverClose = () => {
    setOpenSearchPopover(false);
  };

  useEffect(() => {
    let mounted = true;

    const fetchNotifications = () => {
      axios.get('/api/account/notifications').then((response) => {
        if (mounted) {
          setNotifications(response.data.notifications);
        }
      });
    };

    // fetchNotifications();

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
    >
      <Toolbar>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onOpenNavBarMobile}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/">
          <img
            className={classes.logo}
            alt="Logo"
            src="/images/logos/logo--artstuff-latest.png"
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        {/* <Hidden smDown> */}
        {/*  <div */}
        {/*    className={classes.search} */}
        {/*    ref={searchRef} */}
        {/*  > */}
        {/*    <SearchIcon className={classes.searchIcon} /> */}
        {/*    <Input */}
        {/*      className={classes.searchInput} */}
        {/*      disableUnderline */}
        {/*      onChange={handleSearchChange} */}
        {/*      placeholder="Search your subscribers" */}
        {/*      value={searchValue} */}
        {/*    /> */}
        {/*  </div> */}
        {/*  <Popper */}
        {/*    anchorEl={searchRef.current} */}
        {/*    className={classes.searchPopper} */}
        {/*    open={openSearchPopover} */}
        {/*    transition */}
        {/*  > */}
        {/*    <ClickAwayListener onClickAway={handleSearchPopverClose}> */}
        {/*      <Paper */}
        {/*        className={classes.searchPopperContent} */}
        {/*        elevation={3} */}
        {/*      > */}
        {/*        <List> */}
        {/*          {popularSearches.map((search) => ( */}
        {/*            <ListItem */}
        {/*              button */}
        {/*              key={search} */}
        {/*              onClick={handleSearchPopverClose} */}
        {/*            > */}
        {/*              <ListItemIcon> */}
        {/*                <SearchIcon /> */}
        {/*              </ListItemIcon> */}
        {/*              <ListItemText primary={search} /> */}
        {/*            </ListItem> */}
        {/*          ))} */}
        {/*        </List> */}
        {/*      </Paper> */}
        {/*    </ClickAwayListener> */}
        {/*  </Popper> */}
        {/*  /!* <Button *!/ */}
        {/*  /!*  className={classes.trialButton} *!/ */}
        {/*  /!*  onClick={handlePricingModalOpen} *!/ */}
        {/*  /!*  variant="contained" *!/ */}
        {/*  /!* > *!/ */}
        {/*  /!*  <LockIcon className={classes.trialIcon} /> *!/ */}
        {/*  /!*  Trial expired *!/ */}
        {/*  /!* </Button> *!/ */}
        {/* </Hidden> */}
        {/* <IconButton */}
        {/*  className={classes.chatButton} */}
        {/*  color="inherit" */}
        {/*  onClick={handleChatBarOpen} */}
        {/* > */}
        {/*  <Badge */}
        {/*    badgeContent={6} */}
        {/*    color="secondary" */}
        {/*  > */}
        {/*    <PeopleIcon /> */}
        {/*  </Badge> */}
        {/* </IconButton> */}
        {/* <IconButton */}
        {/*  className={classes.notificationsButton} */}
        {/*  color="inherit" */}
        {/*  onClick={handleNotificationsOpen} */}
        {/*  ref={notificationsRef} */}
        {/* > */}
        {/*  <Badge */}
        {/*    badgeContent={notifications.length} */}
        {/*    classes={{ badge: classes.notificationsBadge }} */}
        {/*    variant="dot" */}
        {/*  > */}
        {/*    <NotificationsIcon /> */}
        {/*  </Badge> */}
        {/* </IconButton> */}
        <Button
          className={classes.logoutButton}
          color="inherit"
          onClick={handleLogout}
        >
          <InputIcon className={classes.logoutIcon} />
          Sign out
        </Button>
      </Toolbar>
      {showLoading && <LinearProgress className={classes.progress}/>}
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
      <PricingModal
        onClose={handlePricingModalClose}
        open={pricingModalOpen}
      />
      <ChatBar
        onClose={handleChatBarClose}
        open={openChatBar}
      />
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
