import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const renderProtectedRoutes = (routes, auth, authPath, extraProps = {}, switchProps = {}) => (routes ? (
  <Switch {...switchProps}>
    {routes.map((route, i) => (
      <Route
        key={route.key || i}
        path={route.path}
        exact={route.exact}
        strict={route.strict}
        render={(props) => {
          // console.log(route);
          // console.log(!route.restricted);
          // console.log(auth);
          // console.log(route.path === authPath);
          if (auth && route.path === authPath) {
            return <Redirect to={{ pathname: '/dashboards/default', state: { from: props.location } }}/>;
          }
          if (!route.restricted || auth || route.path === authPath) {
            return <route.component {...props} {...extraProps} route={route}/>;
          }
          const redirectPath = authPath || '/auth/login';
          return <Redirect to={{ pathname: redirectPath, state: { from: props.location } }}/>;
        }}
      />
    ))}
  </Switch>
) : null);

export default renderProtectedRoutes;
