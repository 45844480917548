import axios from '../../../../utils/axios';
import { ARCHIVED, UN_ARCHIVED } from '../../../aems/Marketing/EmailTemplate/template';

const ACTION_DELETE_TAG = 'requestDeleteTags';
const ACTION_ARCHIVE_TAG = 'requestArchiveTags';
const ACTION_UPDATE_TAG = 'requestUpdateTag';
const ACTION_CREATE_TAG = 'requestCreateTag';

function handleException(action, error, failureProcessor) {
  if (!error.response || !error.response.data || !failureProcessor) {
    return;
  }
  const { data } = error.response;

  let errorMessage = data.reason;
  if (action === ACTION_DELETE_TAG) {
    if (data.errorCode === 'tag.isSystem.invalid') {
      errorMessage = 'System tags cannot be deleted.';
    }
  } else if (action === ACTION_UPDATE_TAG) {
    errorMessage = 'Your tag cannot be updated.';
  } else if (action === ACTION_CREATE_TAG) {
    errorMessage = 'Your tag cannot be created.';
  } else if (action === ACTION_ARCHIVE_TAG) {
    errorMessage = 'Your tag cannot be archived.';
  }
  failureProcessor(errorMessage, data);
}

export const requestTagCategories = (responseProcessor, failureProcessor = null) => {
  axios.get('/api/ext/tagConfigs/categories').then((response) => {
    responseProcessor(response);
  }).catch((error) => {
    handleException('requestTagCategories', error, failureProcessor);
  });
};

export const requestTagGroups = (category, responseProcessor, failureProcessor = null) => {
  axios.get(`/api/ext/tagConfigs/categories/${category}`).then((response) => {
    responseProcessor(response);
  }).catch((error) => {
    handleException('requestTagGroups', error, failureProcessor);
  });
};

export const requestTags = (filterUrl, responseProcessor, failureProcessor = null) => {
  axios.get(`/api/subscriber-tags/${filterUrl || ''}&size=100000`).then((response) => {
    responseProcessor(response);
  }).catch((error) => {
    handleException('requestTags', error, failureProcessor);
  });
};

export const requestDeleteTags = (entityIds, responseProcessor, failureProcessor = null) => {
  axios.delete('/api/ext/tags/', {
    data: {
      ids: entityIds
    }
  }).then((response) => {
    responseProcessor(response);
  }).catch((error) => {
    handleException(ACTION_DELETE_TAG, error, failureProcessor);
  });
};

export const requestArchiveTags = (entityIds, archive, respProcessor, failureProcessor = null) => {
  axios.put('/api/ext/tags/archive', {
    ids: entityIds,
    action: archive ? ARCHIVED : UN_ARCHIVED
  }).then((response) => {
    respProcessor(response);
  }).catch((error) => {
    handleException(ACTION_ARCHIVE_TAG, error, failureProcessor);
  });
};

export const requestUpdateTag = (
  tagId, groupInfo, nameInfo, colorInfo,
  responseProcessor, failureProcessor = null
) => {
  axios.put('/api/ext/tags/', {
    id: tagId,
    group: groupInfo,
    name: nameInfo,
    color: colorInfo
  }).then((response) => {
    responseProcessor(response);
  }).catch((error) => {
    handleException(ACTION_UPDATE_TAG, error, failureProcessor);
  });
};

export const requestCreateTag = (
  categoryInfo, groupInfo, nameInfo, colorInfo,
  responseProcessor, failureProcessor = null
) => {
  axios.post('/api/ext/tags/', {
    category: categoryInfo,
    group: groupInfo,
    name: nameInfo,
    color: colorInfo
  }).then((response) => {
    responseProcessor(response);
  }).catch((error) => {
    handleException(ACTION_CREATE_TAG, error, failureProcessor);
  });
};

export const parseTags = (tags) => {
  const updateTagIds = [];
  const newUpdateTags = [];
  if (tags) {
    tags.forEach((tag) => {
      if (tag.id) {
        updateTagIds.push(tag.id);
      } else {
        const arr = tag.split('>');
        if (arr.length > 1) {
          newUpdateTags.push({ group: arr[0].trim(), name: arr[1].trim() });
        } else {
          newUpdateTags.push({ group: 'Default', name: arr });
        }
      }
    });
  }
  return { tagIds: updateTagIds, newTags: newUpdateTags };
};
