/* These value will be used to sort the column */
export const TEMPLATE_FIELD_NAME = 'templateName';
export const TEMPLATE_FIELD_SUBJECT = 'subject';

export const TEMPLATE_TAG_CATEGORY = 'Mail Template';

export const UN_ARCHIVED = 'UN_ARCHIVED';
export const ARCHIVED = 'ARCHIVED';

export const EDITOR_TYPE_UNLAYER = 'UNLAYER';
export const EDITOR_TYPE_LEGACY = 'LEGACY';

export const defaultTemplateEntity = (defaultName = '', defaultCampaignMailTemplate = false) => ({
  id: '',
  templateName: defaultName,
  brand: {
    id: ''
  },
  brandId: '',
  editorType: EDITOR_TYPE_UNLAYER,
  subject: '',
  preview: '',
  campaignMailTemplate: defaultCampaignMailTemplate,
  templateContent: '',
  lastEditBodyContent: '',
  lastEditBannerContent: '',
  unlayerDesign: '',
  archived: false,
  tags: [],
  attachments: [],
  params: []
});

export const UNLAYER_MERGE_TAGS = {
  brand: {
    name: 'Brand',
    mergeTags: {
      brandName: {
        name: 'Brand Name',
        value: '${ brand.contactName }'
      },
      brandShortCode: {
        name: 'Brand Code',
        value: '${ brand.shortName }'
      },
      brandEmail: {
        name: 'Contact Email',
        value: '${ brand.contactEmail }'
      },
      brandAddress: {
        name: 'Contact Address',
        value: '${ brand.contactAddress }'
      },
      siteURL: {
        name: 'Website URL',
        value: '${ brand.siteUrl }'
      }
    }
  },
  subscriber: {
    name: 'Subscriber',
    mergeTags: {
      firstName: {
        name: 'First Name',
        value: '${ subscriber.firstName }'
      },
      lastName: {
        name: 'Last Name',
        value: '${ subscriber.lastName }'
      },
      email: {
        name: 'Email',
        value: '${ subscriber.email }'
      },
      fullNameOrEmail: {
        name: 'Full Name or Username',
        value: '${ subscriber.fullNameOrEmail() }'
      },
      birthDay: {
        name: 'Birthday',
        value: '${ subscriber.birthDay }'
      }
    }
  },
  mail: {
    name: 'Mail & Campaign Info',
    mergeTags: {
      campaignName: {
        name: 'Campaign Name',
        value: '${ campaign.name }'
      },
      emailSubject: {
        name: 'Email Subject',
        value: '${ campaign.subject }'
      }
    }
  },
  macro: {
    name: 'Predefined Macro',
    mergeTags: {
      unsubscribeLink: {
        name: 'Generate User Unsubscribe Link',
        value: '${ macro[\'subscriber\'].unsubscribe(\'Input your link\') }'
      },
      remoteDiscountCode: {
        name: 'Get Discount Code from E-Commerce Website',
        value: '${ macro[\'promotion\'].getDiscountCode() }'
      },
      remoteDiscountPercentage: {
        name: 'Get Discount Percentage from E-Commerce Website',
        value: '${ macro[\'promotion\'].getDiscountPercentage() }'
      },
      // remoteDiscountProductName: {
      //   name: 'Get Discount Product from E-Commerce Website',
      //   value: '${ macro[\'promotion\'].getDiscountedProductLink() }'
      // },
      // remoteDiscountProductLink: {
      //   name: 'Get Discount Product Link from E-Commerce Website',
      //   value: '${ macro[\'promotion\'].getDiscountedProductLink() }'
      // }
    }
  }
};
