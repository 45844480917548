import React, { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  colors,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  Link,
  List,
  ListSubheader,
  Typography
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import NavItem from 'src/components/Devias/NavItem';
import navConfig from './navConfig';
import { renderUserAvatarText, renderUserFullName } from '../../views/aipms/Management/Customer/customer';
import UserSession from '../../utils/UserSession';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  },
  badgeDot: {
    height: 9,
    minWidth: 9
  },
  onlineBadge: {
    backgroundColor: colors.green[600]
  },
  awayBadge: {
    backgroundColor: colors.orange[600]
  },
  busyBadge: {
    backgroundColor: colors.red[600]
  },
  offlineBadge: {
    backgroundColor: colors.grey[300]
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40
  },
  details: {
    marginLeft: theme.spacing(2)
  },
  moreButton: {
    marginLeft: 'auto',
    color: colors.blueGrey[200]
  }
}));

function renderNavItems(
  {
    // eslint-disable-next-line react/prop-types
    items, subheader, key, ...rest
  }
) {
  return (
    <List key={key}>
      {subheader && <ListSubheader disableSticky>{subheader}</ListSubheader>}
      {/* eslint-disable-next-line react/prop-types */}
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes(
  {
    acc, pathname, item, depth = 0, permissions = []
  }
) {
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={item.href}
        label={item.label}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    let hasPermission = false;
    if (item.permissions && item.permissions.length > 0) {
      item.permissions.forEach((perm) => {
        if (UserSession.getProfile().permissions.includes(perm)) {
          hasPermission = true;
        }
      });
    } else {
      hasPermission = true;
    }
    if (hasPermission) {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          key={item.href}
          label={item.label}
          title={item.title}
        />
      );
    }
  }

  return acc;
}

function NavBar(
  {
    openMobile, onMobileClose, className, ...rest
  }
) {
  const classes = useStyles();

  const location = useLocation();
  // const session = useSelector((state) => state.session);

  // const [userProfile, setUserProfile] = useState(defaultUserProfileEntity());
  //
  // useEffect(() => {
  //   let mounted = true;
  //
  //   requestLoadCurrentUserProfile((response) => {
  //     if (mounted) {
  //       setUserProfile(response.data);
  //     }
  //   });
  //
  //   return () => {
  //     mounted = false;
  //   };
  // }, []);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <nav className={classes.navigation}>
        {navConfig.map((list) => renderNavItems({
          items: list.items,
          subheader: list.subheader,
          pathname: location.pathname,
          key: list.subheader
        }))}
      </nav>
      <Divider className={classes.divider}/>
      <div className={classes.profile}>
        {/* <Badge */}
        {/*  overlap="circle" */}
        {/*  anchorOrigin={{ */}
        {/*    vertical: 'bottom', */}
        {/*    horizontal: 'right', */}
        {/*  }} */}
        {/*  classes={{ */}
        {/*    dot: classes.badgeDot, */}
        {/*    badge: clsx({ */}
        {/*      [classes.badge]: true, */}
        {/*      [classes.onlineBadge]: status === 'online', */}
        {/*      [classes.awayBadge]: status === 'away', */}
        {/*      [classes.busyBadge]: status === 'busy', */}
        {/*      [classes.offlineBadge]: status === 'offline' */}
        {/*    }) */}
        {/*  }} */}
        {/*  variant="dot" */}
        {/* > */}
        {/*  <Avatar */}
        {/*    alt="Person" */}
        {/*    onClick={handleStatusToggle} */}
        {/*    className={classes.avatar} */}
        {/*    src={session.user.avatar} */}
        {/*  /> */}
        {/* </Badge> */}
        <Avatar
          alt="Person"
          onClick={() => {
          }}
          className={classes.avatar}
          src={UserSession.getProfile().avatar}
        >
          {renderUserAvatarText(UserSession.getProfile())}
        </Avatar>
        <div className={classes.details}>
          <Link
            component={RouterLink}
            to="/profile/1/timeline"
            variant="h5"
            color="textPrimary"
            underline="none"
          >
            {renderUserFullName(UserSession.getProfile())}
          </Link>
          <Typography variant="body2">{UserSession.getProfile().jobTitle}</Typography>
        </div>
        <IconButton
          className={classes.moreButton}
          size="small"
        >
          <MoreIcon/>
        </IconButton>
      </div>
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.desktopDrawer
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
