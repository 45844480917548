/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import AemsAnalytics from './views/aems/Dashboard/Analytics';

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboards/default"/>
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('src/views/aipms/Login'))
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('src/views/aipms/Register'))
      },
      {
        component: () => <Redirect to="/errors/error-404"/>
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('src/views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('src/views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('src/views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404"/>
      }
    ]
  },
  {
    route: '*',
    restricted: true,
    component: DashboardLayout,
    routes: [
      /* AEMS */
      {
        path: '/dashboards/default',
        restricted: true,
        exact: true,
        component: AemsAnalytics
      },
      {
        path: '/aems-marketing/email-templates',
        exact: true,
        component: lazy(() => import('src/views/aems/Marketing/EmailTemplate/TemplateManagementList'))
      },
      {
        path: '/aems-marketing/email-templates/create',
        exact: true,
        component: lazy(() => import('src/views/aems/Marketing/EmailTemplate/TemplateUpdate'))
      },
      {
        path: '/aems-marketing/email-templates/edit/:id',
        exact: true,
        component: lazy(() => import('src/views/aems/Marketing/EmailTemplate/TemplateUpdate'))
      },
      {
        path: '/aems-marketing/campaigns/edit/:id',
        exact: true,
        component: lazy(() => import('src/views/aems/Marketing/Campaign/CampaignMailDetail'))
      },
      {
        path: '/aems-marketing/campaigns/create',
        exact: true,
        component: lazy(() => import('src/views/aems/Marketing/Campaign/CampaignMail'))
      },
      {
        path: '/aems-marketing/campaigns',
        exact: true,
        component: lazy(() => import('src/views/aems/Marketing/Campaign/CampaignMailManagementList'))
      },
      {
        path: '/aems-marketing/system-campaigns',
        exact: true,
        component: lazy(() => import('src/views/aems/Marketing/Campaign/CampaignManagementList'))
      },
      {
        path: '/aems-marketing/system-campaigns/create',
        exact: true,
        component: lazy(() => import('src/views/aems/Marketing/Campaign/CampaignSystem'))
      },
      {
        path: '/aems-marketing/system-campaigns/edit/:id',
        exact: true,
        component: lazy(() => import('src/views/aems/Marketing/Campaign/CampaignSystemDetail'))
      },
      {
        path: '/aems-marketing/mail-deliveries',
        exact: true,
        component: lazy(() => import('src/views/aems/Marketing/MailDelivery'))
      },
      /* --------------------------- route mapping for Tickets --------------------------------- */
      {
        path: '/aems-marketing/tickets',
        exact: true,
        component: lazy(() => import('src/views/aems/CustomerSupport/Ticket/TicketQuickList'))
      },
      {
        path: '/aems-marketing/tickets/view/:ticketId',
        exact: true,
        component: lazy(() => import('src/views/aems/CustomerSupport/Ticket/TicketQuickList'))
      },
      {
        path: '/aems-marketing/tickets/:ticket',
        exact: true,
        component: lazy(() => import('src/views/aems/CustomerSupport/Ticket/TicketUpdate'))
      },
      {
        path: '/aems-marketing/my-tickets',
        exact: true,
        component: lazy(() => import('src/views/Mail'))
      },
      /* --------------------------- route mapping for Tag ------------------------------------- */
      {
        path: '/aems-management/customers',
        exact: true,
        component: lazy(() => import('src/views/aipms/Management/Customer/CustomerManagementList'))
      },
      {
        path: '/aems-management/customers/detail/:id',
        exact: true,
        component: lazy(() => import('src/views/aipms/Management/Customer/CustomerManagementDetails'))
      },
      {
        path: '/aems-management/customers/detail/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/aipms/Management/Customer/CustomerManagementDetails'))
      },
      {
        path: '/aems-management/tags',
        exact: true,
        component: lazy(() => import('src/views/aipms/Management/Tag/TagManagementList'))
      },
      /* --------------------------- route mapping for User Profile ---------------------------- */
      {
        path: '/aems-settings',
        exact: true,
        component: lazy(() => import('src/views/aems/Settings/UserProfile'))
      },
      {
        path: '/aems-settings/user-profile',
        exact: true,
        component: lazy(() => import('src/views/aems/Settings/UserProfile'))
      },
      {
        path: '/aems-settings/user-profile/:tab',
        exact: true,
        component: lazy(() => import('src/views/aems/Settings/UserProfile'))
      },
      /* --------------------------- route mapping for Company --------------------------------- */
      {
        path: '/aems-settings/company',
        exact: true,
        component: lazy(() => import('src/views/aems/Settings/Company'))
      },
      {
        path: '/aems-settings/company/:tab',
        exact: true,
        component: lazy(() => import('src/views/aems/Settings/Company'))
      },
      /* --------------------------- route mapping for Application ----------------------------- */
      {
        path: '/aems-settings/application',
        exact: true,
        component: lazy(() => import('src/views/aems/Settings/Application'))
      },
      {
        path: '/aems-settings/application/:tab',
        exact: true,
        component: lazy(() => import('src/views/aems/Settings/Application'))
      },
      {
        component: () => <Redirect to="/errors/error-404"/>
      }
    ]
  }
];
