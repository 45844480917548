import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Container, Grid } from '@material-ui/core';
import Page from 'src/components/Devias/Page';
import Overview from './Overview';
import SubscriberOverview from './SubscriberOverview';
import SubscriberSegmentation from './SubscriberSegmentation';
// import LatestTickets from '../../CustomerSupport/Ticket/Components/LatestTickets';
import { requestSubscriberOverviews } from './api';
import { defaultSubscriberOverview } from './schema';
import Header from '../../../../components/TSC/Page/Header';
import CampaignsStatistic from '../../Marketing/Campaign/Components/CampaignsStatistic';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function AemsAnalytics() {
  const classes = useStyles();

  const [subscriberOverview, setSubscriberOverview] = useState(defaultSubscriberOverview());

  useEffect(() => {
    let mounted = true;

    const fetchEntities = () => {
      requestSubscriberOverviews((response) => {
        if (mounted) {
          setSubscriberOverview(response.data);
        }
      });
    };

    fetchEntities();

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Page
      className={classes.root}
      title="Analytics Dashboard"
    >
      <Container maxWidth={false}>
        <Header
          pageGroup="Dashboard"
          pageTitle="Marketing Overview"
        />
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <Overview/>
          </Grid>
          <Grid
            item
            lg={8}
            xl={9}
            xs={12}
          >
            <SubscriberOverview
              statByDay={subscriberOverview.statByDay}
              statByDayLabel={subscriberOverview.statByDayLabel}
            />
          </Grid>
          <Grid
            item
            lg={4}
            xl={3}
            xs={12}
          >
            <SubscriberSegmentation segmentation={subscriberOverview.segmentation}/>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <CampaignsStatistic/>
          </Grid>
          {/*<Grid*/}
          {/*  item*/}
          {/*  lg={6}*/}
          {/*  md={6}*/}
          {/*  xs={12}*/}
          {/*>*/}
          {/*  <LatestTickets/>*/}
          {/*</Grid>*/}
        </Grid>
      </Container>
    </Page>
  );
}

export default AemsAnalytics;
