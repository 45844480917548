export const defaultBrandOverview = () => ({
  id: '',
  name: '',
  shortName: '',
  color: '',
  totalSubscriber: 0,
  percentage: 0,
});

export const defaultSubscriberSegmentation = () => ({
  id: '',
  name: '',
  shortName: '',
  color: '',
  percentage: 0.0
});
export const defaultSubscriberByMonth = () => ({
  id: '',
  name: '',
  shortName: '',
  color: '',
  totalSubscribers: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
});

export const defaultSubscriberByDay = () => ({
  id: '',
  name: '',
  shortName: '',
  color: '',
  totalSubscribers: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
});

export const defaultSubscriberOverview = () => ({
  segmentation: [
    defaultSubscriberSegmentation()
  ],
  statByMonth: [
    defaultSubscriberByMonth()
  ],
  statByDay: [
    defaultSubscriberByMonth()
  ],
  statByDayLabel: []
});
