import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, CardHeader, Divider, Typography } from '@material-ui/core';
import axios from 'src/utils/axios';
import GenericMoreButton from 'src/components/Devias/GenericMoreButton';
import Chart from './Chart';
import { defaultSubscriberSegmentation } from '../schema';
import { subscriberByMonthDataSet } from '../dashboard';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  chartContainer: {
    padding: theme.spacing(3)
  },
  chart: {
    height: 281
  },
  statsContainer: {
    display: 'flex'
  },
  statsItem: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3, 2),
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.divider}`
    }
  }
}));

function SubscriberSegmentation({ className, segmentation, ...rest }) {
  const classes = useStyles();

  const [chartData, setChartData] = useState([defaultSubscriberSegmentation()]);

  useEffect(() => {
    if (segmentation) {
      setChartData(segmentation);
    }
    return () => {
    };
  }, [segmentation]);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        // action={<GenericMoreButton />}
        title="Subscriber Percentage"
      />
      <Divider />
      <CardContent className={classes.content}>
        <div className={classes.chartContainer}>
          <Chart
            className={classes.chart}
            data={chartData}
          />
        </div>
        <Divider />
        <div className={classes.statsContainer}>
          {chartData.map((data) => (
            <div
              className={classes.statsItem}
              key={data.id}
            >
              <Typography
                align="center"
                component="h6"
                gutterBottom
                variant="overline"
              >
                {data.shortName}
              </Typography>
              <Typography
                align="center"
                variant="h4"
              >
                {Math.round(data.percentage * 100).toFixed(0)}
                %
              </Typography>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}

SubscriberSegmentation.propTypes = {
  className: PropTypes.string,
  segmentation: PropTypes.array
};

export default SubscriberSegmentation;
