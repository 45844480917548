import moment from 'moment';
import { CAMPAIGN_ENABLED_TRUE, CAMPAIGN_TYPE_REGULAR } from './campaign';
import { EDITOR_TYPE_UNLAYER } from '../EmailTemplate/template';

export const campaignSchema = {
  brandId: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  mailTemplateId: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 255
    }
  }
};

export const campaignMailSchema = {
  brandId: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  mailTemplateId: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 255
    }
  },
  fromName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 255
    }
  },
  fromEmail: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 255
    }
  },
  subject: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 255
    }
  }
};

export const defaultCampaignParamEntity = () => ({
  name: '',
  value: '',
  friendlyValue: ''
});

export const defaultCampaignEntity = () => ({
  id: null,
  brand: {
    id: null,
    name: ''
  },
  name: '',
  fromName: '',
  fromEmail: '',
  subject: '',
  type: CAMPAIGN_TYPE_REGULAR,
  scheduledDate: '',
  scheduledEndDate: '',
  enabled: CAMPAIGN_ENABLED_TRUE,
  status: 'NOT_START',
  statusDetail: '',
  mailTemplate: {
    id: null,
    templateName: '',
    params: []
  },
  campaignStatistic: {
    view: 0,
    click: 0,
    notStart: 0,
    inProgress: 0,
    sent: 0,
    bounced: 0,
    complained: 0,
    delivered: 0,
    failed: 0
  },
  dataSourceSubscriberTags: [],
  tags: [],
  strategy: '',
  strategyParams: [{
    name: '',
    value: '',
    friendlyValue: ''
  }]
});
export const defaultCampaignMailEntity = () => ({
  id: null,
  brand: {
    id: '',
    name: ''
  },
  mailTemplate: {
    id: '',
    templateName: '',
    params: [],
    editorType: EDITOR_TYPE_UNLAYER,
    unlayerDesign: ''
  },
  name: '',
  htmlMailContent: '',
  htmlMailBanner: '',
  unlayerDesign: '',
  unlayerTemplateContent: '',
  fromEmail: '',
  fromName: '',
  subject: '',
  monitor: 'DO_NOT_MONITOR',
  dataSourceSubscriberTags: [],
  tags: []
});

export const defaultMailOption = () =>({
  email: '',
  name: ''
})
