import { TagColor } from '../../../../components/TSC/Tag/TagColors';
import { defaultSubscriberByDay } from './schema';


export const brandColor = (brandId, color = null) => {
  if (color) {
    return color;
  }
  switch (brandId) {
    case 1:
      return TagColor.INDIGO;
    case 2:
      return TagColor.GREEN;
    case 3:
      return TagColor.DEEP_ORANGE;
    case 4:
      return TagColor.BROWN;
    case 5:
      return TagColor.GREY;
    case 6:
      return TagColor.TEAL;
    case 7:
      return TagColor.LIGHT_BLUE;
    default:
      return TagColor.AMBER;
  }
};

export const subscriberByDayDataSet = (subscriberByMonth = defaultSubscriberByDay()) => (
  {
    label: subscriberByMonth.name,
    backgroundColor: brandColor(subscriberByMonth.id, subscriberByMonth.color),
    borderColor: brandColor(subscriberByMonth.id, subscriberByMonth.color),
    data: subscriberByMonth.totalSubscribers,
    fill: false,
    barThickness: 12,
    maxBarThickness: 10,
    barPercentage: 0.5,
    categoryPercentage: 0.5
  }
);
