import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  headerTitle: {
    marginBottom: theme.spacing(4)
  }
}));

function Header({ className, classNameGridItem, pageGroup, pageTitle, ...rest }) {
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="flex-end"
        container
        justify="space-between"
        spacing={3}
      >
        <Grid item className={clsx(classes.root, classNameGridItem)}>
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >
            {pageGroup}
          </Typography>
          <Typography
            component="h1"
            variant="h3"
            className={classes.headerTitle}
          >
            {pageTitle}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  classNameGridItem: PropTypes.string,
  pageGroup: PropTypes.string,
  pageTitle: PropTypes.string
};

Header.defaultProps = {
  classNameGridItem: '',
};

export default Header;
